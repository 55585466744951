export class ArrayHelper {
    static merge = function (...arrays: string[][]): string[] {
        return [...new Set(arrays.flat())];
    };

    /** Fisher-Yates shuffle */
    static shuffle = function <T>(array: T[]): T[] {
        const copiedArray = [...array];
        for (let i = copiedArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [copiedArray[i], copiedArray[j]] = [copiedArray[j], copiedArray[i]];
        }
        return copiedArray;
    };
}
